import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = [];

const groupSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroups(state, action) {
      return action.payload;
    },
    deleteGroups(state, action) {
      return state.filter((group) => group.id !== action.payload);
    },
    updateGroup(state, action) {
      return state.map((account) => (account.id === action.payload.id ? action.payload : account));
    },
  },
});

export const { setGroups, deleteGroups, updateGroup } = groupSlice.actions;
export default groupSlice.reducer;

// process.env.REACT_APP_BASEURL
export const allGroups = () => (dispatch) => {
  axios.get(`${process.env.REACT_APP_BASEURL}/api/groups`)
    .then((res) => dispatch(setGroups(res.data))).catch(console.log);
};

export const deleteGroup = (id) => (dispatch) => {
  axios.delete(`/api/groups/${id}`)
    .then(() => dispatch(deleteGroups(id)))
    .catch(console.log);
};

export const editGroup = (id, updatedData) => (dispatch) => {
  axios
    .put(`${process.env.REACT_APP_BASEURL}/api/groups/${id}`, updatedData)
    .then((res) => dispatch(updateGroup(res.data)))
    .catch(console.log);
};
