import {
  SET_MESSAGE, ADD_MESSAGE, CURRENT_MESSAGE, CLEAR_MESSAGE, EDIT_MESSAGE,
} from '../types';

export default function messageReducer(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case SET_MESSAGE:
      return payload;
    case ADD_MESSAGE:
      return [...state, payload];
    case CURRENT_MESSAGE:
      return payload;
    case CLEAR_MESSAGE:
      return state.filter((post) => post.id !== payload);
    case EDIT_MESSAGE:
      return state.map((el) => (el.id === payload.id ? payload : el));
    default:
      return state;
  }
}
