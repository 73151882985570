import axios from 'axios';
import {
  SET_READER, ADD_READER, CURRENT_READER, CLEAR_READER, EDIT_READER,
} from '../types';

export const setReader = (payload) => ({ type: SET_READER, payload });
export const addReader = (payload) => ({ type: ADD_READER, payload });
export const setCurrentReader = (payload) => ({ type: CURRENT_READER, payload });
export const clearReader = (payload) => ({ type: CLEAR_READER, payload });
export const editReader = (payload) => ({ type: EDIT_READER, payload });

export const setReadersThunk = () => (dispatch) => {
  axios('/api/readers')
    .then((res) => dispatch(setReader(res.data)))
    .catch(console.log);
};

export const editReaderThunk = (input, id) => (dispatch) => {
  axios
    .patch(`/api/readers/patchReaders/${id}`, { input })
    .then((res) => dispatch(editReader(res.data)))
    .catch((error) => console.log('Error updating reader:', error));
};

export const deleteReaderAsync = (id) => (dispatch) => {
  axios.delete(`/api/readers/${id}`)
    .then(() => dispatch(clearReader(id)))
    .catch(console.log);
};
