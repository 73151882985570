/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
} from 'reactstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { editAccount } from '../../redux/Slices/AccountsSlice/AccountsSlice';

export default function MyAddAccountsModal({ isOpen, toggle, account }) {
  const dispatch = useDispatch();
  const [input, setInput] = useState({
    login: account?.login || '',
    name: account?.name || '',
    surname: account?.surname || '',
    passwordNew: '',
  });

  const [showPasswordNew, setShowPasswordNew] = useState(false);

  const changeHandler = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(editAccount(account.id, input));
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} />
      <Card className="border-0 shadow-lg">
        <CardBody>
          <CardTitle className="mb-4 mt-3 text-center" tag="h2">
            Редактирование пользователя
          </CardTitle>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="exampleEmail">Логин:</Label>
              <Input
                id="exampleEmail"
                name="login"
                value={input.login}
                type="text"
                onChange={changeHandler}
                style={{ width: '100%' }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleName">Имя Пользователя:</Label>
              <Input
                id="exampleName"
                name="name"
                value={input.name}
                type="text"
                onChange={changeHandler}
                style={{ width: '100%' }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleSurname">Фамилия Пользователя:</Label>
              <Input
                id="exampleSurname"
                name="surname"
                value={input.surname}
                type="text"
                onChange={changeHandler}
                style={{ width: '100%' }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Новый пароль:</Label>
              <Input
                id="examplePassword"
                name="passwordNew"
                value={input.passwordNew}
                type={showPasswordNew ? 'text' : 'password'}
                autoComplete="off"
                onChange={changeHandler}
                style={{ width: '100%' }}
              />
              <div className="eye-iconOld" onClick={() => setShowPasswordNew(!showPasswordNew)}>
                {showPasswordNew ? <FaEye /> : <FaEyeSlash />}
              </div>
            </FormGroup>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button color="primary" type="submit" style={{ marginRight: '10px' }}>Сохранить изменения</Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Modal>
  );
}
