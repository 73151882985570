import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = [];

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccounts(state, action) {
      return action.payload;
    },
    deleteAccounts(state, action) {
      return state.filter((account) => account.id !== action.payload);
    },
    updateAccount(state, action) {
      return state.map((account) => (account.id === action.payload.id ? action.payload : account));
    },
  },
});

export const { setAccounts, deleteAccounts, updateAccount } = accountsSlice.actions;
export default accountsSlice.reducer;

export const allAccounts = () => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_BASEURL}/api/addmanagers`)
    .then((res) => dispatch(setAccounts(res.data)))
    .catch(console.log);
};

export const deleteAccount = (id) => (dispatch) => {
  axios
    .delete(`${process.env.REACT_APP_BASEURL}/api/addmanagers/${id}`)
    .then(() => dispatch(deleteAccounts(id)))
    .catch(console.log);
};

export const editAccount = (id, updatedData) => (dispatch) => {
  axios
    .put(`${process.env.REACT_APP_BASEURL}/api/addmanagers/${id}`, updatedData)
    .then((res) => dispatch(updateAccount(res.data)))
    .catch(console.log);
};
