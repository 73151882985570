import { CLEAR_TASK, EDIT_TASK, SET_TASKS } from '../types';

export default function tasksReducer(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case SET_TASKS:
      return payload;
    case CLEAR_TASK:
      return state.filter((post) => post.id !== payload);
    case EDIT_TASK:
      return state.map((el) => (el.id === payload.id ? payload : el));
    default:
      return state;
  }
}
