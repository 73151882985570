import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { setLengthThunk } from '../../redux/actions/lengthAction';
import { deleteMessageAsync, setMessagesThunk } from '../../redux/actions/messageAction';
import InputModalSlice from '../InputModalSlice';

export default function MyOneMessage({ oneMessage, path }) {
  const teams = useSelector((state) => state.topic);
  const allMessages = useSelector((state) => state.messages);
  const dispatch = useDispatch();
  function setName(num) {
    const res = teams.filter((t) => t.id === num);
    return res.map((el) => el.name).join();
  }
  function setStatus(bool) {
    if (bool === false) {
      dispatch(setLengthThunk());
      return '⭕';
    }
    if (bool === true) {
      dispatch(setLengthThunk());
      return '✅';
    }

    return '❓';
  }

  function setName2(num, num2) {
    const res = teams.filter((t) => t.id === num);
    const oneTeam = res.map((el) => el.Sections);
    const final = oneTeam.flat().filter((item) => item.id === num2);
    if (final.length === 0) {
      return oneTeam.flat().map((el) => el.undername).pop();
    }
    return final.map((el) => el.undername).pop();
  }
  const [modal, setModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const handleClick = (text) => {
    setSelectedMessage(text);
    setModal(true);
  };

  const deleteHandler = async () => {
    try {
      await dispatch(deleteMessageAsync(oneMessage.id));
      await dispatch(setMessagesThunk('mymessages'));
      // The message has been deleted, so now update the badge count
      dispatch(setLengthThunk());
    } catch (error) {
      console.log('Error deleting message:', error);
    }
  };

  function convertDateFormat(date) {
    const parts = date.split('-');
    const day = parts[2];
    const month = parts[1];
    const year = parts[0];
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  }
  return (

    <tr className="text-center">
      <td>
        {allMessages.findIndex((el) => el.id === oneMessage.id) + 1}
      </td>
      <td>
        {oneMessage['Users.login']}
      </td>
      <td>
        {convertDateFormat(oneMessage.dateDoc)}
      </td>
      <td>
        {oneMessage.timeDoc}
      </td>
      <td>
        {oneMessage.fileSend}
      </td>
      <td>
        {setName(oneMessage.level_id)}
      </td>
      <td>
        {setName2(oneMessage.level_id, oneMessage.section_id)}
      </td>
      {path === 'allmessages' ? (
        <td>
          {setStatus(oneMessage.status)}
        </td>
      )
        : (
          <>
            <td>
              <Button
                onClick={deleteHandler}
                color="danger"
              >
                Удалить
              </Button>
            </td>
            <td>
              <Button
                onClick={() => handleClick(oneMessage)}
                color="success"
              >
                Редактировать
              </Button>
            </td>
            {modal && (
            <InputModalSlice
              selectedMessage={selectedMessage}
              modal={modal}
              setModal={setModal}
              onClose={() => setModal(false)}
            />
            )}
          </>
        )}
    </tr>
  );
}
