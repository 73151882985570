import React from 'react';
import { Progress } from 'reactstrap';

export default function MyProgressBar({ wave }) {
  function getMaxHundred(num) {
    if (num > 100) {
      return 100;
    }
    return num;
  }
  return (
    <Progress
      style={{
        height: '30px',
      }}
      animated
      color="primary"
      value={wave}
    >
      {getMaxHundred(wave)}
      %
    </Progress>
  );
}
