/* eslint-disable max-len */
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Col, Form, FormGroup, Input, Label, Modal, ModalHeader,
} from 'reactstrap';
import { editMessageThunk } from '../../redux/actions/messageAction';

export default function InputModalSlice({
  modal, setModal, selectedMessage,
}) {
  const teams = useSelector((state) => state.topic);
  const logicTeams = teams.slice();
  function setNumber(str) {
    const final = teams.filter((t) => t.name === str);
    const res = final.map((el) => el.id);
    return res[0];
  }
  const dispatch = useDispatch();
  const toggle = () => {
    setModal(!modal);
  };
  const [input, setInput] = useState({
    dateDoc: selectedMessage?.dateDoc,
    timeDoc: selectedMessage?.timeDoc,
    fileSend: selectedMessage?.fileSend,
    level_id: selectedMessage?.level_id,
    section_id: selectedMessage?.section_id,
  });
  const [selectedState, setSelectedState] = useState(selectedMessage?.level_id);
  const changeHandler = useCallback((e) => setInput((prev) => ({ ...prev, [e.target.name]: e.target.value })), []);
  const updateHandler = (inputs, id) => {
    dispatch(editMessageThunk(inputs, id));
  };
  function getCorrect(arr) {
    const indPl1 = arr.findIndex((el) => el.id === selectedMessage.level_id);
    const removed = arr.splice(indPl1, 1);
    arr.unshift(removed[0]);
    return arr;
  }
  function getCorrect2(arr) {
    const indPl = arr.findIndex((el) => el.id === selectedMessage.section_id);
    const removed = arr.splice(indPl, 1);
    arr.unshift(removed[0]);
    return arr;
  }
  const arrCorrectPlayers = teams?.map((el) => el.Sections).flat().filter((el) => el.teams_id === Number(selectedState)).map((res) => res);
  const correctArrPlayers = getCorrect2(arrCorrectPlayers);
  function getNumberForDebug(number) {
    return teams?.map((el) => el.Sections).flat().filter((el) => el.teams_id === number).map((el) => el.id)
      .pop();
  }
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Редактирование сообщения:</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          updateHandler(input, selectedMessage.id);
        }}
      >
        <FormGroup style={{ padding: '0 20px' }}>
          {' '}
          {/* Добавлено стилизованное пространство слева и справа */}
          <Label for="exampleDate">
            Дата публикации:
          </Label>
          <Col sm={12}>
            <Input
              id="exampleDate"
              name="dateDoc"
              value={input.dateDoc}
              placeholder="date placeholder"
              type="date"
              onChange={changeHandler}
            />
          </Col>
        </FormGroup>
        <FormGroup style={{ padding: '0 20px' }}>
          {' '}
          {/* Добавлено стилизованное пространство слева и справа */}
          <Label for="exampleTime">
            Время публикации:
          </Label>
          <Col sm={12}>
            <Input
              id="exampleTime"
              name="timeDoc"
              value={input.timeDoc}
              placeholder="time placeholder"
              type="time"
              onChange={changeHandler}
            />
          </Col>
        </FormGroup>
        <FormGroup style={{ padding: '0 20px' }}>
          {' '}
          {/* Добавлено стилизованное пространство слева и справа */}
          <Label for="exampleSelect">
            Категория
          </Label>
          <Input
            id="exampleSelect"
            name="level_id"
            type="select"
            onClick={(e) => {
              setInput((prev) => ({ ...prev, [e.target.name]: setNumber(e.target.value), section_id: getNumberForDebug(setNumber(e.target.value)) }));
              setSelectedState(setNumber(e.target.value));
            }}
          >
            {
            getCorrect(logicTeams)?.map((category) => (
              <option
                key={category.id}
                value={category.name}
              >
                {category.name}
              </option>
            ))
          }
          </Input>
        </FormGroup>
        <FormGroup style={{ padding: '0 20px' }}>
          {' '}
          {/* Добавлено стилизованное пространство слева и справа */}
          {selectedState && (
          <>
            <Label for="exampleSelect">
              Подкатегория
            </Label>
            <Input
              id="exampleSelect"
              name="section_id"
              type="select"
              onClick={changeHandler}
            >
              {correctArrPlayers?.map((underCategory) => (
                <option
                  value={underCategory.id}
                  key={underCategory.id}
                >
                  {underCategory.undername}
                </option>
              ))}
            </Input>
          </>
          )}
        </FormGroup>
        <FormGroup style={{ padding: '0 20px' }}>
          {' '}
          {/* Добавлено стилизованное пространство слева и справа */}
          <Button
            type="submit"
            color="primary"
            size="lg"
            onClick={() => {
              setTimeout(() => {
                setModal(!modal);
              }, 0);
            }}
          >
            Сохранить изменения
          </Button>
        </FormGroup>
      </Form>
    </Modal>
  );
}
