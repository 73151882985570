import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userReducer';
import TopicSlice from './Slices/TopicSlice/TopicSlice';
import modalSlice from './Slices/ModalSlice/modalSlice';
import CategorSlice from './Slices/CategorySlice/CategorSlice';
import GroupsSlice from './Slices/GroupsSlice/GroupsSlice';
import messageReducer from './reducers/messageReducer';
import tasksReducer from './reducers/tasksReducer';
import lengthReducer from './reducers/lengthReducer';
import readerReducer from './reducers/readerReducer';
import AccountsSlice from './Slices/AccountsSlice/AccountsSlice';

export const store = configureStore({
  reducer: {
    topic: TopicSlice,
    user: userReducer,
    categor: CategorSlice,
    modal: modalSlice,
    messages: messageReducer,
    length: lengthReducer,
    readers: readerReducer,
    tasks: tasksReducer,
    groups: GroupsSlice,
    accounts: AccountsSlice,
  },
});
