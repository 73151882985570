/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { editGroup } from '../../redux/Slices/GroupsSlice/GroupsSlice';

export default function MyAddGroupsModal({ isOpen, toggle, account }) {
  const dispatch = useDispatch();
  const [input, setInput] = useState({
    name: account?.name || '',
    number: account?.number || '',
  });

  const changeHandler = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Добавьте здесь логику для сохранения изменений
    // console.log(input, account.id);
    dispatch(editGroup(account.id, input));
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} />
      <Card className="border-0 shadow-lg">
        <CardBody>
          <CardTitle className="mb-4 mt-3 text-center" tag="h2">
            Редактирование группы Телеграмм
          </CardTitle>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="exampleName">название Группы:</Label>
              <Input
                id="exampleName"
                name="name"
                value={input.name}
                type="text"
                onChange={changeHandler}
                style={{ width: '100%' }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleNumber">Номер Пользователя:</Label>
              <Input
                id="exampleNumber"
                name="number"
                value={input.number}
                type="text"
                onChange={changeHandler}
                style={{ width: '100%' }}
              />
            </FormGroup>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button color="primary" type="submit" style={{ marginRight: '10px' }}>Сохранить изменения</Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Modal>
  );
}
