import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MyMessages from '../MyMessages/MyMessages';
import MyPagination from '../Pagination/MyPagination';

export default function AllMessages() {
  const allMessages = useSelector((state) => state.messages);
  const [currentPage, setCurrentPage] = useState(1);
  const [messagePerPage] = useState(15);
  const lastMessageIndex = currentPage * messagePerPage;
  const firstMessageIndex = lastMessageIndex - messagePerPage;
  const currentMessage = allMessages.slice(firstMessageIndex, lastMessageIndex);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const nextPage = () => setCurrentPage((prev) => prev + 1);
  const prevPage = () => setCurrentPage((prev) => prev - 1);
  return (
    <div className="mt-4">
      {' '}
      <MyMessages currentMessage={currentMessage} />
      <div className="mt-3">
        {' '}
        <MyPagination
          paginate={paginate}
          nextPage={nextPage}
          prevPage={prevPage}
          messagePerPage={messagePerPage}
        />
      </div>
    </div>
  );
}
