/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Button, Row, Col, Form, Card, CardBody, CardTitle, FormGroup, Input, Label,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setTasksThunk } from '../../redux/actions/tasksAction';
import { allGroups } from '../../redux/Slices/GroupsSlice/GroupsSlice';

export default function Shablon({ modalOpen, toggleModal }) {
  const onlyGroups = useSelector((state) => state.groups);
  // console.log(onlyGroups);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allGroups());
  }, []);
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [frequency, setFrequency] = useState('');
  const [myError, setMyError] = useState('');
  const idUser = useSelector((state) => state.user).id;
  const [difference, setDifference] = useState('0');
  const [selectedChats, setSelectedChats] = useState([]);

  const textHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BASEURL}/api/text`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message, frequency, idUser, difference, selectedChats,
        }),
      });
      if (response.ok) {
        // console.log('Сообщение успешно отправлено на бэкенд');
        toggleModal(false);
        navigate('/tasks');
        setMessage('');
        setFrequency('');
        setMyError('');
        setDifference('0');
        dispatch(setTasksThunk());
      } else {
        const responseData = await response.json();
        if (responseData.message === 'Все поля обязательны к заполнению!') {
          setMyError(responseData.message);
        } else if (responseData.message === 'Некорректный формат частоты!') {
          setMyError(responseData.message);
        } else if (responseData.message === 'Произошла ошибка при планировании сообщения') {
          setMyError(responseData.message);
        } else if (responseData.message === 'Укажите срок отправки!') {
          setMyError(responseData.message);
        }
      }
    } catch (error) {
      console.error('Произошла ошибка:', error);
    }
  };

  // useEffect(() => {
  //   dispatch(setTasksThunk());
  // }, [textHandler]);

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleDifferenceChange = (e) => {
    setDifference(e.target.value);
  };

  const applyFormatting = (start, end, formatter) => {
    const beforeSelection = message.substring(0, start);
    const selectedText = message.substring(start, end);
    const afterSelection = message.substring(end);
    return beforeSelection + formatter(selectedText) + afterSelection;
  };

  const handleButtonClick = (formatter) => {
    const input = document.getElementById('exampleName');
    const start = input.selectionStart;
    const end = input.selectionEnd;
    setMessage(applyFormatting(start, end, formatter));
  };
  const makeBold = (text) => `[makeBold]${text}[makeBold]`;
  const makeItalic = (text) => `[makeItalic]${text}[makeItalic]`;
  // const makeMonospace = (text) => `\`${text}\``;
  const makeZacherknutyy = (text) => `[makeZacherknuty]${text}[makeZacherknuty]`;
  const makeSpoiler = (text) => `[makeSpoiler]${text}[makeSpoiler]`;
  const makeUnderline = (text) => `[makeUnderline]${text}[makeUnderline]`;
  const makeUrl = (text) => `[makeUrlSt]${text}[makeUrlMid]www.yandex.ru[makeUrlEnd]`;

  const makeDeadLineText = () => '[MakeDeadLine]';
  const makeDeadLine = () => {
    const input = document.getElementById('exampleName');
    const start = input.selectionStart;
    const end = input.selectionEnd;
    setMessage(applyFormatting(start, end, makeDeadLineText));
  };

  return (
    <div className="mt-5" style={{ backgroundImage: "url('background.jpg')", backgroundSize: 'cover' }}>
      <Row className="justify-content-center">
        <Col lg="8">
          <Card className="border-0 shadow-lg">
            <CardBody>
              <CardTitle className="mb-4 mt-3 text-center" tag="h2">
                Форма для создания задач
              </CardTitle>
              <div className="d-flex flex-wrap justify-content-center gap-2">
                <Button onClick={() => handleButtonClick(makeBold)}>Жирный</Button>
                <Button onClick={() => handleButtonClick(makeItalic)}>Курсив</Button>
                {/* <Button onClick={() => handleButtonClick(makeMonospace)}>Моноширинный</Button> */}
                <Button onClick={() => handleButtonClick(makeZacherknutyy)}>Зачеркнутый</Button>
                <Button onClick={() => handleButtonClick(makeSpoiler)}>Исчезающий(спойлер)</Button>
                <Button onClick={() => handleButtonClick(makeUnderline)}>Подчеркнутый</Button>
                <Button onClick={() => handleButtonClick(makeUrl)}>Ссылка</Button>
                <Button onClick={makeDeadLine}>Добавить Дедлайн</Button>
              </div>
              {/* <Button onClick={() => dispatch(deleteGroup(4))}>Удалить</Button> */}
              <Form onSubmit={textHandler}>
                <FormGroup floating>
                  <Input
                    id="exampleName"
                    name="name"
                    type="textarea"
                    value={message}
                    onChange={handleInputChange}
                    style={{ height: '150px', marginTop: '10px' }}
                  />
                  <Label for="exampleName">
                    Введите текст...
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label for="exampleFrequency">Частота(Минуты(0-59) Часы(0-24) День(1-31) Месяц(1-12) День недели(0-6)):</Label>
                  <Input
                    id="exampleFrequency"
                    name="frequency"
                    type="text"
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                    placeholder="Введите частоту"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="exampleDifference">Кол-во дней до Дедлайна:</Label>
                  <Input
                    id="exampleDifference"
                    name="difference"
                    type="select"
                    value={difference}
                    onChange={handleDifferenceChange}
                  >
                    <option value="">Выберите кол-во дней</option>
                    {[...Array(7)].map((_, index) => (
                      <option key={index + 1} value={index + 1}>{index + 1}</option>
                    ))}
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label for="exampleChats">Выберите чат(ы):</Label>
                  <Input
                    id="exampleChats"
                    name="chats"
                    type="select"
                    multiple // Добавьте атрибут multiple для разрешения множественного выбора
                    value={selectedChats}
                    onChange={(e) => setSelectedChats(Array.from(e.target.selectedOptions, (option) => option.value))}
                  >
                    {/* Опции для выбора */}
                    {onlyGroups.map((group) => (
                      <option key={group.id} value={group.number}>{group.name}</option>
                    ))}
                    {/* Если поле `name` в объекте `group` содержит значения, отображаем их */}
                    {/* Добавьте другие чаты по необходимости */}
                  </Input>
                </FormGroup>

                {myError && (
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ color: 'red', display: 'block', marginTop: '30px' }}>
                      {myError}
                    </span>
                  </div>
                )}
                <Button color="primary" type="submit" style={{ display: 'block', margin: '0 auto', marginTop: '10px' }}>
                  Отправить
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </div>
  );
}
