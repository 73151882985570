/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { memo, useEffect, useState } from 'react';
import {
  Table, Row, Col, Card, CardBody, CardTitle,
  Button,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setMessagesThunk } from '../../redux/actions/messageAction';
import { allTopics } from '../../redux/Slices/TopicSlice/TopicSlice';
import MyOneMessage from '../MyOneMessage';
import MySpinner from '../MySpinner/MySpinner';
import MyForm from '../MyForm/MyForm';

function MyMessages({ currentMessage }) {
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const icon = messageModalOpen ? '➖' : '➕';
  const toggleMessageModal = () => {
    setMessageModalOpen(!messageModalOpen);
  };
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const path = location.pathname.replace('/', '');
  const allMessages = useSelector((state) => state.messages);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true); // Start loading
    dispatch(allTopics());
    dispatch(setMessagesThunk(path)).then(() => {
      setLoading(false);
    });
  }, [path]);
  if (loading) {
    return <MySpinner />;
  }

  return (

    <div className="mt-5" style={{ backgroundImage: "url('background.jpg')", backgroundSize: 'cover' }}>
      <div style={{ marginTop: '15px' }}>
        {path === 'mymessages' ? (
          <Button
            outline
            color="link"
            style={{ float: 'right' }}
            onClick={() => toggleMessageModal()}
          >
            {icon}
          </Button>
        ) : null}
        {messageModalOpen ? <MyForm toggleModal={toggleMessageModal} /> : null}
      </div>
      <Row className="justify-content-center" style={{ marginTop: '20px' }}>
        <Col lg="12">
          <Card className="border-0 shadow-lg">
            <CardBody>
              <CardTitle className="mb-4 mt-3 text-center" tag="h2">
                Таблица сообщений
              </CardTitle>
              <Table hover bordered size="">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th className="text-center">Автор</th>
                    <th className="text-center">Дата получения</th>
                    <th className="text-center">Время получения</th>
                    <th className="text-center">Название Файл</th>
                    <th className="text-center">Название Категории</th>
                    <th className="text-center">Название Подкатегории</th>
                    {path !== 'mymessages' && <th className="text-center">Статус</th>}
                  </tr>
                </thead>
                <tbody>
                  {allMessages.length === 0 ? (
                    <tr>
                      <td colSpan="7" align="center">
                        <strong>Сообщений к отправке на данный момент нет!</strong>
                      </td>
                    </tr>
                  ) : (
                    (path === 'allmessages'
                      ? currentMessage?.map((message) => (
                        <MyOneMessage path={path} oneMessage={message} key={message.id} />
                      ))
                      : (path === 'mymessages')
                        ? allMessages?.map((message) => (
                          <MyOneMessage path={path} oneMessage={message} key={message.id} />
                        ))
                        : null)
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default memo(MyMessages);
