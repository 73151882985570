/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table, Button, CardTitle, Row, Col, Card, CardBody,
} from 'reactstrap';
import { deleteTaskAsync, setTasksThunk } from '../../redux/actions/tasksAction';
import TaskModal from './TaskModal';
import Shablon from '../Shablon/Shablon';
import EditTaskModal from '../EditTaskModal/EditTaskModal';

export default function MyTasks() {
  const [shablonModalOpen, setShablonModalOpen] = useState(false);
  const groups = useSelector((state) => state.groups);
  // console.log(groups, '~~~');
  const icon = shablonModalOpen ? '➖' : '➕';
  const toggleShablonModal = () => {
    setShablonModalOpen(!shablonModalOpen);
  };
  const allTasks = useSelector((state) => state.tasks);
  const dispatch = useDispatch();
  const handleDelete = (taskId) => {
    dispatch(deleteTaskAsync(taskId));
  };
  const [modal, setModal] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);

  const openHandler = (taskId) => {
    setSelectedTaskId(taskId);
    setModal(true);
  };

  useEffect(() => {
    dispatch(setTasksThunk());
  }, [modal]);

  return (

    <div className="task-container mt-5" style={{ backgroundImage: "url('background.jpg')", backgroundSize: 'cover' }}>
      <div style={{ marginTop: '15px' }}>
        <Button
          outline
          color="link"
          style={{ float: 'right' }}
          onClick={() => toggleShablonModal()}
        >
          {icon}
        </Button>
        {shablonModalOpen ? <Shablon modalOpen={shablonModalOpen} toggleModal={toggleShablonModal} /> : null}
      </div>
      <Row className="justify-content-center" style={{ marginTop: '20px' }}>
        <Col lg="12">
          <Card className="border-0 shadow-lg">
            <CardBody>
              <CardTitle className="mb-4 mt-3 text-center" tag="h2">
                Таблица задач
              </CardTitle>
              <Table hover bordered size="">
                <thead style={{ textAlign: 'center' }}>
                  <tr>
                    <th>Автор</th>
                    <th>Сообщение</th>
                    <th>Частота</th>
                    <th>Разница дней</th>
                    <th>Срок</th>
                    <th>Получатели</th>
                    <th>Действие</th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: 'center' }}>
                  {allTasks.length === 0 ? (
                    <tr>
                      <td colSpan="7" align="center">
                        <b>Нет Задач</b>
                      </td>
                    </tr>
                  ) : allTasks?.map((task) => (
                    <tr key={task.id}>
                      <td>{task['User.login']}</td>
                      <td style={{ textAlign: 'left', paddingLeft: '40px' }}>
                        <pre style={{ margin: '0', whiteSpace: 'pre-wrap' }}>
                          {task.message.replace(/\\/g, '').replace(/[\\*_~|[\]]/g, '')}
                        </pre>
                      </td>
                      <td>{task.frequency}</td>
                      <td>{task.difference}</td>
                      <td>{task.srok}</td>
                      <td>
                        {task.groups_id?.map((oneEl) => {
                          const group = groups.find((el) => el.number === oneEl);
                          if (group) {
                            return (
                              <span key={oneEl}>
                                {group.name}
                                {oneEl !== task.groups_id[task.groups_id.length - 1] && ', '}
                              </span>
                            );
                          }
                          return null;
                        })}
                      </td>
                      <td style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                        <Button
                          onClick={() => openHandler(task.id)}
                          outline
                          color="link"
                          style={{ display: 'inline-block' }}
                        >
                          ✏️
                        </Button>
                        <Button
                          onClick={() => handleDelete(task.id)}
                          outline
                          color="link"
                          style={{ display: 'inline-block' }}
                        >
                          🗑️
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {modal && <EditTaskModal modal={modal} setModal={setModal} toggle={() => setModal(false)} taskId={selectedTaskId} />}
    </div>

  );
}
