import axios from 'axios';
import { CLEAR_TASK, EDIT_TASK, SET_TASKS } from '../types';

export const setTasks = (payload) => ({ type: SET_TASKS, payload });
export const clearTasks = (payload) => ({ type: CLEAR_TASK, payload });
export const editTask = (payload) => ({ type: EDIT_TASK, payload });

export const setTasksThunk = () => (dispatch) => new Promise((resolve, reject) => {
  axios('/api/text')
    .then((res) => {
      dispatch(setTasks(res.data));
      resolve();
    })
    .catch((error) => {
      console.error(error);
      reject(error);
    });
});

export const deleteTaskAsync = (id) => (dispatch) => {
  axios.delete(`/api/text/${id}`)
    .then(() => dispatch(clearTasks(id)))
    .catch(console.log);
};

export const editTaskThunk = (input, id) => (dispatch) => {
  // Создаем новый объект, чтобы избежать переопределения параметров функции
  const updatedInput = { ...input };
  if (input && input.message) {
    // Выполняем замены всех типов форматирования
    updatedInput.message = updatedInput.message
      .replace(/\*/g, '[makeBold]') // Одинарная звездочка для полужирного
      .replace(/__(.+?)__/g, '[makeUnderline]$1[makeUnderline]') // Два нижних подчеркивания для подчеркивания
      .replace(/(^|[^@])_(.*?)_(?!\w)/g, '$1[makeItalic]$2[makeItalic]') // Одиночное подчеркивание для курсива, не затрагивающее никнеймы
      .replace(/~(.+?)~/g, '[makeZacherknuty]$1[makeZacherknuty]') // Две тильды для зачёркнутого текста
      .replace(/\|\|/g, '[makeSpoiler]'); // Две вертикальные черты для спойлера
  }
  axios
    .patch(`/api/text/${id}`, { input: updatedInput })
    .then((res) => dispatch(editTask(res.data)))
    .catch(console.log);
};
