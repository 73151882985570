/* eslint-disable max-len */
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Row, Label, Container,
} from 'reactstrap';
import { setLengthThunk } from '../../redux/actions/lengthAction';
import { setModal } from '../../redux/Slices/ModalSlice/modalSlice';
import { allTopics } from '../../redux/Slices/TopicSlice/TopicSlice';
import MyModal from '../MyModal/MyModal';
import MyProgressBar from '../MyProgressBar';
import { allGroups } from '../../redux/Slices/GroupsSlice/GroupsSlice';
import { setMessagesThunk } from '../../redux/actions/messageAction';

function MyForm({ modalOpen, toggleModal }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal);
  const user = useSelector((state) => state.user);
  const nick = user.id;
  const [fileData, setFileData] = useState();
  const onlyGroups = useSelector((state) => state.groups);
  const [selectedChats, setSelectedChats] = useState([]);
  const filesLoad = (e) => {
    setFileData(e.target.files);
  };

  const [wave, setWave] = useState(0);
  const progressHandler = (e) => {
    e.preventDefault();
    setWave((prev) => prev + 17);
  };
  const filesHandler = (e) => {
    try {
      e.preventDefault();
      const data = new FormData(e.target);
      data.append('files', fileData);
      data.append('nickName', nick);
      fetch(`${process.env.REACT_APP_BASEURL}/api/`, {
        method: 'POST',
        body: data,
      }).then((res) => {
        if (res.status === 400) {
          dispatch(setModal(!modal));
        } if (res.status === 200) {
          dispatch(setLengthThunk());
          // navigate('/mymessages');
          dispatch(setMessagesThunk('mymessages'));
          toggleModal(false);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const [selectedState, setSelectedState] = useState(null);
  const teams = useSelector((state) => state.topic);
  useEffect(() => {
    dispatch(allGroups());
    dispatch(allTopics());
    if (teams && teams.length > 0) {
      setSelectedState(teams[0].id);
    }
  }, []);
  return (
    <div className="mt-5" style={{ backgroundImage: "url('background.jpg')", backgroundSize: 'cover' }}>
      <Container>
        <Row className="justify-content-center">
          <Col lg="10">
            <Card className="border-0 shadow-lg">
              <CardBody>
                <CardTitle className="mb-4 mt-3 text-center" tag="h2">
                  Форма для отправки документов
                </CardTitle>
                <MyProgressBar wave={wave} />
                <Form onSubmit={filesHandler}>
                  <FormGroup>
                    <Label for="exampleFile">
                      Файл для отправки:
                    </Label>
                    <Input
                      id="exampleFile"
                      name="files"
                      type="file"
                      onChange={filesLoad}
                      onInput={progressHandler}
                      multiple
                    />
                  </FormGroup>
                  <MyModal />
                  <FormGroup>
                    <Label for="exampleDate">
                      Дата публикации:
                    </Label>
                    <Input
                      id="exampleDate"
                      name="dateDoc"
                      placeholder="date placeholder"
                      type="date"
                      onBlur={progressHandler}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleTime">
                      Время публикации:
                    </Label>
                    <Input
                      id="exampleTime"
                      name="timeDoc"
                      placeholder="time placeholder"
                      type="time"
                      onBlur={progressHandler}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">
                      Категория:
                    </Label>
                    <Input
                      id="exampleSelect"
                      name="level_id"
                      type="select"
                      onClick={(e) => { setSelectedState(e.target.value); }}
                      onInput={progressHandler}
                    >
                      {
          teams?.filter((team) => team.Sections && team.Sections.length > 0).map((team) => (
            <option
              key={team.id}
              value={team.id}
            >
              {team.name}
            </option>
          ))
        }
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    {selectedState && (
                    <>
                      <Label for="exampleSelect">
                        Подкатегория:
                      </Label>
                      <Input
                        id="exampleSelect"
                        name="section_id"
                        type="select"
                        onClick={progressHandler}
                      >
                        {teams?.map((el) => el.Sections)
                          .flat().filter((el) => el.teams_id === Number(selectedState))
                          .map((res) => res)
                          .map((player) => (
                            <option
                              value={player.id}
                              key={player.id}
                            >
                              {player.undername}
                            </option>
                          ))}
                      </Input>
                    </>
                    )}

                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleChats">Выберите чат(ы):</Label>
                    <Input
                      id="exampleChats"
                      name="chats"
                      type="select"
                      multiple
                      value={selectedChats}
                      onChange={(e) => setSelectedChats(Array.from(e.target.selectedOptions, (option) => option.value))}
                    >
                      {/* Опции для выбора */}
                      {onlyGroups.map((group) => (
                        <option key={group.id} value={group.number}>{group.name}</option>
                      ))}
                      {/* Если поле `name` в объекте `group` содержит значения, отображаем их */}
                      {/* Добавьте другие чаты по необходимости */}
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    {wave >= 100
                      ? (
                        <Button
                          type="submit"
                          color="success"
                        >
                          Отправить
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          color="primary"
                        >
                          Отправить
                        </Button>
                      )}
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default memo(MyForm);
