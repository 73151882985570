import React from 'react';
import { Spinner } from 'reactstrap';

export default function MySpinner() {
  return (
    <div style={{
      position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)',
    }}
    >
      <div style={{ textAlign: 'center' }}>
        <Spinner style={{ width: '5rem', height: '5rem', marginBottom: '1rem' }} color="primary" />
        <p style={{ fontSize: '1.5rem' }}>Загружается...</p>
      </div>
    </div>
  );
}
