import {
  SET_READER, ADD_READER, CURRENT_READER, CLEAR_READER, EDIT_READER,
} from '../types';

export default function readerReducer(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case SET_READER:
      return payload;
    case ADD_READER:
      return [...state, payload];
    case CURRENT_READER:
      return payload;
    case CLEAR_READER:
      return state.filter((post) => post.id !== payload);
    case EDIT_READER:
      return state.map((el) => (el.id === payload.id ? payload : el));
    default:
      return state;
  }
}
