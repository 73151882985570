// User
export const SET_AUTH = 'SET_AUTH';
export const LOGOUT = 'LOGOUT';
export const UPDATE_USER = 'UPDATE_USER';

// Message
export const SET_MESSAGE = 'SET_MESSAGE';
export const CURRENT_MESSAGE = 'CURRENT_MESSAGE';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const EDIT_MESSAGE = 'EDIT_MESSAGE';

// Tasks
export const SET_TASKS = 'SET_TASKS';
export const CLEAR_TASK = 'CLEAR_TASK';
export const EDIT_TASK = 'EDIT_TASK';

// Length
export const SET_LENGTH = 'SET_LENGTH';

// Readers
export const SET_READER = 'SET_READER';
export const ADD_READER = 'ADD_READER';
export const CURRENT_READER = 'CURRENT_READER';
export const CLEAR_READER = 'CLEAR_READER';
export const EDIT_READER = 'EDIT_READER';
