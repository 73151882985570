import React from 'react';
import { useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export default function MyPagination({
  paginate, nextPage, prevPage, messagePerPage,
}) {
  const allmessages = useSelector((state) => state.messages);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(allmessages.length / messagePerPage); i += 1) {
    pageNumbers.push(i);
  }
  return (
    <Pagination>
      <PaginationItem>
        <PaginationLink
          onClick={prevPage}
          previous
        />
      </PaginationItem>
      {pageNumbers.map((pageNumber) => (
        <PaginationItem key={pageNumber}>
          <PaginationLink onClick={() => paginate(pageNumber)}>
            {' '}
            {pageNumber}
            {' '}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem>
        <PaginationLink
          onClick={nextPage}
          next
        />
      </PaginationItem>
    </Pagination>
  );
}
