import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal, ModalBody, ModalHeader,
} from 'reactstrap';

export default function ProfileModal({ modal, setModal, changeFactor }) {
  const dispatch = useDispatch();
  const toggle = () => dispatch(setModal(!modal));
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Внимание!</ModalHeader>
        <ModalBody>
          {changeFactor === 'Данные изменены' && 'Данные успешно изменены'}
          {changeFactor === 'Пароль не совпадает!' && 'Пароль не совпадает!'}
          {changeFactor === 'Заполните поле с паролем' && 'Заполните поле с паролем'}
        </ModalBody>
      </Modal>
    </div>
  );
}
