/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Col, Form, FormGroup, Input, Label, Modal,
  ModalHeader,
} from 'reactstrap';
import { editTaskThunk, setTasksThunk } from '../../redux/actions/tasksAction';

export default function EditTaskModal({ taskId, modal, setModal }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTasksThunk());
  }, []);

  const oneTask = useSelector((state) => state.tasks).filter((oneId) => oneId.id === taskId)[0];
  const groups = useSelector((state) => state.groups);
  const [inputEdit, setInputEdit] = useState({
    message: oneTask?.message || '',
    frequency: oneTask?.frequency || '',
    srok: oneTask?.srok || '',
    difference: oneTask?.difference || '',
    groups_id: oneTask?.groups_id || [],
    user_id: oneTask?.user_id || '',
  });

  // const changeHandler = useCallback((e) => setInputEdit((prev) => ({ ...prev, [e.target.name]: e.target.value })), []);
  const changeHandler = useCallback((e) => {
    const { name, value, options } = e.target;
    if (name === 'groups_id') {
      const selectedValues = Array.from(options).filter((option) => option.selected).map((option) => option.value);
      setInputEdit((prev) => ({ ...prev, [name]: selectedValues }));
    } else {
      setInputEdit((prev) => ({ ...prev, [name]: value }));
    }
  }, []);
  const updateHandler = (inputs, id) => {
    dispatch(editTaskThunk(inputs, id));
  };

  const applyFormatting = (start, end, formatter) => {
    const beforeSelection = inputEdit.message.substring(0, start);
    const selectedText = inputEdit.message.substring(start, end);
    const afterSelection = inputEdit.message.substring(end);
    return beforeSelection + formatter(selectedText) + afterSelection;
  };

  const handleButtonClick = (formatter) => {
    const input = document.getElementById('exampleMessage');
    const start = input.selectionStart;
    const end = input.selectionEnd;
    const newMessage = applyFormatting(start, end, formatter);
    setInputEdit((prev) => ({ ...prev, message: newMessage }));
  };

  const makeBold = (text) => `[makeBold]${text}[makeBold]`;
  const makeItalic = (text) => `[makeItalic]${text}[makeItalic]`;
  // const makeMonospace = (text) => `\`${text}\``;
  const makeZacherknutyy = (text) => `[makeZacherknuty]${text}[makeZacherknuty]`;
  const makeSpoiler = (text) => `[makeSpoiler]${text}[makeSpoiler]`;
  const makeUnderline = (text) => `[makeUnderline]${text}[makeUnderline]`;
  const makeUrl = (text) => `[makeUrlSt]${text}[makeUrlMid]www.yandex.ru[makeUrlEnd]`;

  const makeDeadLineText = () => '[MakeDeadLine]';
  const makeDeadLine = () => {
    const input = document.getElementById('exampleMessage');
    const start = input.selectionStart;
    const end = input.selectionEnd;
    setInputEdit((prev) => ({ ...prev, message: applyFormatting(start, end, makeDeadLineText) }));
  };

  return (
    <div className="mt-5" style={{ backgroundImage: "url('background.jpg')", backgroundSize: 'cover', padding: '20px' }}>
      <Modal isOpen={modal} toggle={() => setModal(!modal)} style={{ maxWidth: '800px', width: '80%' }}>
        <ModalHeader toggle={() => setModal(!modal)}>Редактирование задачи:</ModalHeader>
        <div className="d-flex flex-wrap justify-content-center gap-2" style={{ padding: '10px' }}>
          <Button onClick={() => handleButtonClick(makeBold)}>Жирный</Button>
          <Button onClick={() => handleButtonClick(makeItalic)}>Курсив</Button>
          <Button onClick={() => handleButtonClick(makeZacherknutyy)}>Зачеркнутый</Button>
          <Button onClick={() => handleButtonClick(makeSpoiler)}>Исчезающий(спойлер)</Button>
          <Button onClick={() => handleButtonClick(makeUnderline)}>Подчеркнутый</Button>
          <Button onClick={() => handleButtonClick(makeUrl)}>Ссылка</Button>
          <Button onClick={makeDeadLine}>Добавить Дедлайн</Button>
        </div>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            updateHandler(inputEdit, taskId);
          }}
        >
          <FormGroup style={{ padding: '0 20px' }}>
            <Label for="exampleMessage">Сообщение:</Label>
            <Col sm={12}>
              <Input
                id="exampleMessage"
                name="message"
                value={inputEdit.message.replace(/\\/g, '')
                  .replace(/\*/g, '[makeBold]')
                  .replace(/(?<!\w)__(.*?)__(?!\w)/g, '[makeUnderline]$1[makeUnderline]') // Заменяем двойные подчеркивания на [makeUnderline]
                  .replace(/(?<!\w)_(.*?)_(?!\w)/g, '[makeItalic]$1[makeItalic]') // Заменяем одинарные подчеркивания на [makeItalic]
                  .replace(/~/g, '[makeZacherknuty]')
                  .replace(/\|\|/g, '[makeSpoiler]')}
                  // .replace(/\[\]/g, '[makeUrlSt]')
                  // .replace(/\[\]/g, '[makeUrlMid]')
                  // .replace(/\[\]/g, '[makeUrlEnd]')
                placeholder="...сообщение"
                type="textarea"
                onChange={changeHandler}
                style={{ height: '175px' }}
              />
            </Col>
          </FormGroup>

          <FormGroup style={{ padding: '0 20px' }}>
            <Label for="exampleFrequency">Частота:</Label>
            <Col sm={12}>
              <Input
                id="exampleFrequency"
                name="frequency"
                value={inputEdit.frequency}
                placeholder="...частота"
                type="text"
                onChange={changeHandler}
              />
            </Col>
          </FormGroup>

          <FormGroup style={{ padding: '0 20px' }}>
            <Label for="exampleSrok">Срок:</Label>
            <Col sm={12}>
              <Input
                id="exampleSrok"
                name="srok"
                value={inputEdit.srok}
                placeholder="...срок"
                type="select"
                onChange={changeHandler}
              >
                <option value="date">Дата</option>
                <option value="no date">Без даты</option>
              </Input>
            </Col>
          </FormGroup>

          <FormGroup style={{ padding: '0 20px' }}>
            <Label for="exampleGroups">Группы:</Label>
            <Col sm={12}>
              <Input
                id="exampleGroups"
                name="groups_id"
                value={inputEdit.groups_id}
                placeholder="...группы"
                type="select"
                onChange={changeHandler}
                multiple
              >
                {
            groups?.map((oneName) => (
              <option
                key={oneName.id}
                value={oneName.number}
              >
                {oneName.name}
              </option>
            ))
          }
              </Input>
            </Col>
          </FormGroup>

          <FormGroup style={{ padding: '0 20px' }}>
            <Label for="exampleDifference">Разница:</Label>
            <Col sm={12}>
              <Input
                id="exampleDifference"
                name="difference"
                value={inputEdit.difference}
                placeholder="...разница"
                type="text"
                onChange={changeHandler}
              />
            </Col>
          </FormGroup>

          <FormGroup style={{ padding: '0 20px' }}>
            <Button
              type="submit"
              color="primary"
              size="lg"
              onClick={() => {
                dispatch(setTasksThunk());
                setTimeout(() => {
                  setModal(!modal);
                }, 0);
              }}
            >
              Сохранить изменения
            </Button>
          </FormGroup>
        </Form>
      </Modal>
    </div>
  );
}
