/* eslint-disable max-len */
import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, CardTitle, Col, Form, FormGroup, Input, Label, Row, CardBody, Card,
} from 'reactstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { updateUser } from '../../redux/actions/userActions';
import ProfileModal from './ProfileModal';
import './UserProfile.css';

export default function UserProfile() {
  const dispatch = useDispatch();
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [newPas, setNewPas] = useState(false);
  const user = useSelector((state) => state.user);
  const [input, setInput] = useState({
    id: user.id,
    login: user.login,
    name: user.name,
    surname: user.surname,
    passwordOld: '',
    passwordNew: '',
  });

  const changeHandler = useCallback((e) => setInput((prev) => ({ ...prev, [e.target.name]: e.target.value })), []);
  const [modal, setModal] = useState(false);
  const [changeFactor, setChangeFactor] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      id, login, name, surname, passwordOld, passwordNew,
    } = input;
    axios
      .put(`${process.env.REACT_APP_BASEURL}/api/profile`, {
        id,
        login,
        name,
        surname,
        passwordOld,
        passwordNew,
      })
      .then((res) => {
        if (res.data.message === 'Заполните поле с паролем') {
          setChangeFactor('Заполните поле с паролем');
          setModal(!modal);
        }
        if (res.data.message === 'Profile updated successfully') {
          setChangeFactor('Данные изменены');
          setModal(!modal);
          dispatch(updateUser(res.data.user));
          setInput({
            id: res.data.user.id,
            login: res.data.user.login,
            name: res.data.user.name,
            surname: res.data.user.surname,
            passwordOld: '',
            passwordNew: '',
          });
        }
        if (res.data.message === 'Invalid old password') {
          setChangeFactor('Пароль не совпадает!');
          setModal(!modal);
        }
        if (res.data.message === 'Correct') {
          setNewPas(true);
        }
      })
      .catch((error) => {
        console.error('Error updating profile:', error);
      });
  };

  return (
    <div className="mt-5" style={{ backgroundImage: "url('background.jpg')", backgroundSize: 'cover' }}>
      <Row className="justify-content-center">
        <Col lg="8">
          <Card className="border-0 shadow-lg">
            <CardBody>
              <CardTitle className="mb-4 mt-3 text-center" tag="h2">
                Мой профиль
              </CardTitle>
              <ProfileModal modal={modal} setModal={setModal} changeFactor={changeFactor} />
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="exampleEmail">Логин:</Label>
                  <Input
                    id="exampleEmail"
                    name="login"
                    value={input.login}
                    type="text"
                    onChange={changeHandler}
                    style={{ width: '100%' }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="exampleName">Имя Пользователя:</Label>
                  <Input
                    id="exampleName"
                    name="name"
                    value={input.name}
                    type="text"
                    onChange={changeHandler}
                    style={{ width: '100%' }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="exampleSurname">Фамилия Пользователя:</Label>
                  <Input
                    id="exampleSurname"
                    name="surname"
                    value={input.surname}
                    type="text"
                    onChange={changeHandler}
                    style={{ width: '100%' }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="examplePassword">Текущий пароль (для внесения изменений):</Label>
                  <Input
                    id="examplePassword"
                    name="passwordOld"
                    value={input.passwordOld}
                    type={showPasswordOld ? 'text' : 'password'}
                    autoComplete="off"
                    onChange={changeHandler}
                    style={{ width: '100%' }}
                  />
                  <div className="eye-iconOld" onClick={() => setShowPasswordOld(!showPasswordOld)}>
                    {showPasswordOld ? <FaEye /> : <FaEyeSlash />}
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label for="exampleNewPassword">Новый пароль (если хотите изменить):</Label>
                  <Input
                    id="exampleNewPassword"
                    name="passwordNew"
                    value={input.passwordNew}
                    type={showPasswordNew ? 'text' : 'password'}
                    autoComplete="off"
                    onChange={changeHandler}
                    style={{ width: '100%' }}
                  />
                  <div className="eye-iconNew" onClick={() => setShowPasswordNew(!showPasswordNew)}>
                    {showPasswordNew ? <FaEye /> : <FaEyeSlash />}
                  </div>
                </FormGroup>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button color="primary" type="submit" style={{ marginRight: '10px' }}>Сохранить изменения</Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
