import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  Navbar, Nav, NavItem, NavbarText, Row, Col, Button, Badge,
} from 'reactstrap';
import { setLengthThunk } from '../../redux/actions/lengthAction';
import { logoutUserAsync } from '../../redux/actions/userActions';
import './MyNavbar.css';

function MyNavbar() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const lengthForBadge = useSelector((state) => state.length);

  useEffect(() => {
    dispatch(setLengthThunk());
  }, [lengthForBadge, dispatch]);

  return (
    <Row>
      <Col>
        <Navbar>
          <Nav fill pills>
            {!user.id ? (
              null
            ) : (
              <>
                {/* <NavItem>
                  <NavLink to="/">Форма Отправки</NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink to="/text">Шаблоны</NavLink>
                </NavItem> */}
                {user.level === true ? (
                  <>
                    <NavItem>
                      <NavLink to="/addcategory">Категории</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/addmanagers">Пользователи</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/addgroups">Группы</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/readers">Подписчики</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/allmessages">Все Документы</NavLink>
                      {/* <Badge pill color="success">{lengthForBadge}</Badge> */}
                    </NavItem>
                  </>
                ) : null }
                <NavItem>
                  <NavLink to="/mymessages">Мои Документы</NavLink>
                  <Badge pill color="success">{lengthForBadge}</Badge>
                </NavItem>
                <NavItem>
                  <NavLink to="/tasks">Задачи</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/profile">Профиль</NavLink>
                </NavItem>
              </>
            )}
          </Nav>
          <div className="d-flex justify-content-between">
            <NavbarText>
              {user.name ? (
                <strong>
                  Приветствуем,
                  {' '}
                  {user.name}
                  !
                </strong>
              ) : null}
            </NavbarText>
            {user.id && (
            <Nav key="logout">
              <Button color="danger" onClick={() => dispatch(logoutUserAsync())} className="logout-button">Выйти</Button>
            </Nav>
            )}
          </div>
        </Navbar>
      </Col>
    </Row>
  );
}

export default MyNavbar;
