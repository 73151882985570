/* eslint-disable max-len */
import React, { memo, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Table,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import ShablonAddManager from './ShablonAddManager';
import MyAddAccountsModal from './MyAddAccountsModal'; // Импортируйте ваш модальный компонент
import { allAccounts, deleteAccount } from '../../redux/Slices/AccountsSlice/AccountsSlice';
import './MyAddManagers.css';

function MyAddManagers() {
  const accounts = useSelector((state) => state.accounts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allAccounts());
  }, [dispatch]);

  const [shablonModalOpen, setShablonModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(null);

  const icon = shablonModalOpen ? '➖' : '➕';

  const toggleShablonModal = () => {
    setShablonModalOpen(!shablonModalOpen);
  };

  const toggleEditModal = (account) => {
    setCurrentAccount(account);
    setEditModalOpen(!editModalOpen);
  };

  const handleDelete = (accountId) => {
    dispatch(deleteAccount(accountId));
  };

  return (
    <div className="mt-5" style={{ backgroundImage: "url('background.jpg')", backgroundSize: 'cover' }}>
      <div style={{ marginTop: '15px' }}>
        <Button
          outline
          color="link"
          style={{ float: 'right' }}
          onClick={toggleShablonModal}
        >
          {icon}
        </Button>
        {shablonModalOpen && <ShablonAddManager modalOpen={shablonModalOpen} toggleModal={toggleShablonModal} />}
      </div>
      <Row className="justify-content-center" style={{ marginTop: '20px' }}>
        <Col lg="12">
          <Card className="border-0 shadow-lg">
            <CardBody>
              <CardTitle className="mb-4 mt-3 text-center" tag="h2">
                Таблица Пользователей
              </CardTitle>
              <Table hover bordered size="">
                <thead style={{ textAlign: 'center' }}>
                  <tr>
                    <th>Намбер</th>
                    <th>Логин</th>
                    <th>Имя</th>
                    <th>Фамилия</th>
                    {/* <th>Пароль</th> */}
                    <th>Редактировать</th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: 'center' }}>
                  {accounts.length === 0 ? (
                    <tr>
                      <td colSpan="7" align="center">
                        <b>Нет Зарегистрированных Пользователей</b>
                      </td>
                    </tr>
                  ) : accounts.map((task, index) => (
                    <tr key={task.id}>
                      <td>{index + 1}</td>
                      <td>{task.login}</td>
                      <td>{task.name}</td>
                      <td>{task.surname}</td>
                      {/* <td>{task.password.slice(0, 7)}</td> */}
                      <td style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                        <Button
                          onClick={() => toggleEditModal(task)}
                          outline
                          color="link"
                          style={{ display: 'inline-block' }}
                        >
                          ✏️
                        </Button>
                        <Button
                          onClick={() => handleDelete(task.id)}
                          outline
                          color="link"
                          style={{ display: 'inline-block' }}
                        >
                          🗑️
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {editModalOpen && (
        <MyAddAccountsModal
          isOpen={editModalOpen}
          toggle={() => toggleEditModal(null)}
          account={currentAccount}
        />
      )}
    </div>
  );
}

export default memo(MyAddManagers);
