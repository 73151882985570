/* eslint-disable max-len */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Card, CardBody,
  CardTitle,
  Col,
  Row,
  Table,
} from 'reactstrap';
import { allGroups, deleteGroup } from '../../redux/Slices/GroupsSlice/GroupsSlice';
import ShablonGroup from './ShablonGroup';
import MyAddGroupsModal from './MyAddGroupsModal';

export default function MyAddGroup() {
  const [shablonModalOpen, setShablonModalOpen] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [modal, setModal] = useState(false);
  const icon = shablonModalOpen ? '➖' : '➕';
  const toggleShablonModal = () => {
    setShablonModalOpen(!shablonModalOpen);
  };

  const onlyGroups = useSelector((state) => state.groups);
  const dispatch = useDispatch();
  const handleDelete = (groupId) => {
    dispatch(deleteGroup(groupId));
  };
  const [editModalOpen, setEditModalOpen] = useState(false);

  useEffect(() => {
    dispatch(allGroups());
  }, [dispatch]);

  const toggleEditModal = (account) => {
    setCurrentAccount(account);
    setEditModalOpen(!editModalOpen);
  };

  return (
    <>
      <div style={{ marginTop: '15px' }}>
        <Button
          outline
          color="link"
          style={{ float: 'right' }}
          onClick={() => toggleShablonModal()}
        >
          {icon}
        </Button>
        {shablonModalOpen ? <ShablonGroup modalOpen={shablonModalOpen} toggleModal={toggleShablonModal} /> : null}
      </div>
      <Row className="justify-content-center" style={{ marginTop: '20px' }}>
        <Col lg="12">
          <Card className="border-0 shadow-lg">
            <CardBody>
              <CardTitle className="mb-4 mt-3 text-center" tag="h2">
                Таблица групп Телеграмм
              </CardTitle>
              <Table hover bordered size="">
                <thead style={{ textAlign: 'center' }}>
                  <tr>
                    <th>Номер</th>
                    <th>Название группы</th>
                    <th>Айди группы</th>
                    <th>Действия</th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: 'center' }}>
                  {onlyGroups?.length === 0 ? (
                    <tr>
                      <td colSpan="7" align="center">
                        <b>Нет Групп</b>
                      </td>
                    </tr>
                  ) : onlyGroups?.map((task) => (
                    <tr key={task.id}>
                      <td>{task.id}</td>
                      <td>{task.name}</td>
                      <td>{task.number}</td>

                      <td style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                        <Button
                          onClick={() => toggleEditModal(task)}
                          outline
                          color="link"
                          style={{ display: 'inline-block' }}
                        >
                          ✏️
                        </Button>
                        <Button
                          onClick={() => handleDelete(task.id)}
                          outline
                          color="link"
                          style={{ display: 'inline-block' }}
                        >
                          🗑️
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {editModalOpen && (
        <MyAddGroupsModal
          isOpen={editModalOpen}
          toggle={() => toggleEditModal(null)}
          account={currentAccount}
        />
      )}
    </>
  );
}
