import axios from 'axios';
import { SET_LENGTH } from '../types';

export const setLength = (payload) => ({ type: SET_LENGTH, payload });

export const setLengthThunk = () => (dispatch) => {
  axios('/api/allmessages')
    .then((res) => {
      const allDocuments = res.data;
      const documentsWithFalseStatus = allDocuments.filter((doc) => !doc.status);
      dispatch(setLength(documentsWithFalseStatus.length));
    })
    .catch(console.log);
};
