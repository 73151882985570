import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Card, CardBody, CardTitle, Col, Row, Table,
} from 'reactstrap';
import { deleteReaderAsync, editReaderThunk, setReadersThunk } from '../../redux/actions/readerAction';
// import OneReader from '../OneReader/OneReader';

export default function Readers() {
  const allReaders = useSelector((state) => state.readers);
  console.log(allReaders, 'ALLLL');
  const InReaders = allReaders.filter((one) => one.allow === false);

  const myReaders = allReaders.filter((one) => one.allow === true);
  console.log(myReaders, 'YESSSs');
  const [input, setInput] = useState(Boolean);
  const dispatch = useDispatch();

  const deleteReaderHandler = (id) => {
    dispatch(deleteReaderAsync(id));
  };
  const updateReaderHandler = (inputs, id) => {
    // setInput((prevInput) => !prevInput);
    dispatch(editReaderThunk(!inputs, id));
  };

  useEffect(() => {
    dispatch(setReadersThunk());
  }, []);

  return (
    <>
      <div className="mt-5" style={{ backgroundImage: "url('background.jpg')", backgroundSize: 'cover' }}>
        <Row className="justify-content-center" style={{ marginTop: '20px' }}>
          <Col lg="12">
            <Card className="border-0 shadow-lg">
              <CardBody>
                <CardTitle className="mb-4 mt-3 text-center" tag="h2">
                  Подписчики без подтверждения
                </CardTitle>
                <Table hover bordered size="">
                  <thead style={{ textAlign: 'center' }}>
                    <tr>
                      <th>№</th>
                      <th>Логин Пользователя</th>
                      <th>Имя Пользователя</th>
                      <th>Фамилия Пользователя</th>
                      <th>Телефон Пользователя</th>
                      <th>Действия</th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: 'center' }}>
                    {InReaders.length === 0 ? (
                      <tr>
                        <td colSpan="7" align="center">
                          <b>Нет Заявок</b>
                        </td>
                      </tr>
                    ) : InReaders.map((task, index) => (
                      <tr key={task.id}>
                        {/* <td>{task.id}</td> */}
                        <td>{index + 1}</td>
                        <td>{task.nickName}</td>
                        <td>{task.first_name}</td>
                        <td>{task.last_name}</td>
                        <td>{task.phone_number}</td>
                        <td style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                          <Button
                            value={input}
                            onClick={() => {
                              updateReaderHandler(input, task.id);
                            }}
                            outline
                            color="link"
                            style={{ display: 'inline-block' }}
                          >
                            ✅
                          </Button>
                          <Button
                            onClick={() => deleteReaderHandler(task.id)}
                            outline
                            color="link"
                            style={{ display: 'inline-block' }}
                          >
                            ❌
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="mt-5" style={{ backgroundImage: "url('background.jpg')", backgroundSize: 'cover' }}>
        <Row className="justify-content-center" style={{ marginTop: '20px' }}>
          <Col lg="12">
            <Card className="border-0 shadow-lg">
              <CardBody>
                <CardTitle className="mb-4 mt-3 text-center" tag="h2">
                  Текущие пользователя Бота
                </CardTitle>
                <Table hover bordered size="">
                  <thead style={{ textAlign: 'center' }}>
                    <tr>
                      <th>№</th>
                      <th>Логин Пользователя</th>
                      <th>Имя Пользователя</th>
                      <th>Фамилия Пользователя</th>
                      <th>Телефон Пользователя</th>
                      <th>Действия</th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: 'center' }}>
                    {myReaders.length === 0 ? (
                      <tr>
                        <td colSpan="7" align="center">
                          <b>Актуальных подписчиков бота нет</b>
                        </td>
                      </tr>
                    ) : myReaders.map((task, index) => (
                      <tr key={task.id}>
                        {/* <td>{task.id}</td> */}
                        <td>{index + 1}</td>
                        <td>{task.nickName}</td>
                        <td>{task.first_name}</td>
                        <td>{task.last_name}</td>
                        <td>{task.phone_number}</td>
                        <td style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                          <Button
                            onClick={() => deleteReaderHandler(task.id)}
                            outline
                            color="link"
                            style={{ display: 'inline-block' }}
                          >
                            🗑️
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
