import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = [];

const topicSlice = createSlice({
  name: 'topic',
  initialState,
  reducers: {
    setTopics(state, action) {
      return action.payload;
    },

  },
});

export const { setTopics } = topicSlice.actions;
export default topicSlice.reducer;

export const allTopics = () => (dispatch) => {
  axios.get(`${process.env.REACT_APP_BASEURL}/api`)
    .then((res) => dispatch(setTopics(res.data))).catch(console.log);
};
