import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert, Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Label,
  Row,
} from 'reactstrap';
import { setModal } from '../../redux/Slices/ModalSlice/modalSlice';
import { allGroups, deleteGroup } from '../../redux/Slices/GroupsSlice/GroupsSlice';
import MyModal from '../MyModal/MyModal';

export default function ShablonGroup({ modalOpen, toggleModal }) {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [input, setInput] = useState({
    name: '',
    number: '',
  });
  const [seeAlert, setSeeAlert] = useState(false);
  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      const res = await axios.post(`${process.env.REACT_APP_BASEURL}/api/groups`, input);
      if (res.data.message === 'Заполните обязательные поля!') {
        dispatch(setModal(!modal));
      } else if (res.data.message === 'ок!') {
        toggleModal(false);
        setSeeAlert(true);
        setInput({
          name: '',
          number: '',
        });
        dispatch(allGroups());
        setTimeout(() => {
          setSeeAlert(false);
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const inputHandler = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div className="mt-5">
      <Row className="justify-content-center">
        <Col lg="6">
          <Card className="border-0 shadow-lg mb-4">
            <CardBody>
              <CardTitle className="mb-4 mt-3 text-center" tag="h2">
                Форма для создания групп получателей 📱
              </CardTitle>
              <Form onSubmit={submitHandler}>
                <FormGroup>
                  <Label for="exampleEmail">
                    Название группы/канала
                  </Label>
                  <Input
                    id="exampleName"
                    name="name"
                    placeholder="Введите название группы/канала..."
                    type="text"
                    value={input.name}
                    onChange={inputHandler}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="examplePassword">Номер айди группы/канала</Label>
                  <Input
                    id="exampleNumber"
                    name="number"
                    placeholder="Введите номер айди группы/канала..."
                    type="text"
                    value={input.number}
                    onChange={inputHandler}
                  />
                </FormGroup>
                {seeAlert && <Alert color="success">Группа/Канал добавлены</Alert>}
                <Button color="primary" type="submit">Создать</Button>
                <MyModal />
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
