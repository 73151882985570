import axios from 'axios';
import {
  SET_MESSAGE, ADD_MESSAGE, CURRENT_MESSAGE, CLEAR_MESSAGE, EDIT_MESSAGE,
} from '../types';

export const setMessage = (payload) => ({ type: SET_MESSAGE, payload });
export const addMessage = (payload) => ({ type: ADD_MESSAGE, payload });
export const setCurrentMessage = (payload) => ({ type: CURRENT_MESSAGE, payload });
export const clearMessage = (payload) => ({ type: CLEAR_MESSAGE, payload });
export const editMessage = (payload) => ({ type: EDIT_MESSAGE, payload });

export const setMessagesThunk = (path) => (dispatch) => new Promise((resolve, reject) => {
  axios(`/api/${path}`)
    .then((res) => {
      dispatch(setMessage(res.data));
      resolve();
    })
    .catch((error) => {
      console.error(error);
      reject(error);
    });
});

export const editMessageThunk = (input, id) => (dispatch) => {
  axios
    .patch(`/api/mymessages/patchMessages/${id}`, { input })
    .then((res) => dispatch(editMessage(res.data)))
    .catch(console.log);
};

export const deleteMessageAsync = (id) => (dispatch) => {
  axios.delete(`/api/mymessages/${id}`)
    .then(() => dispatch(clearMessage(id)))
    .catch(console.log);
};
