import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import {
  Button, Col, Form, FormGroup, Input, Label, Row, Container,
} from 'reactstrap';
import { loginUser } from '../../redux/actions/userActions';

export default function AuthPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [myErr, setMyErr] = useState('');
  const dispatch = useDispatch();
  const login = async (e) => {
    try {
      const res = await dispatch(loginUser(e, Object.fromEntries(new FormData(e.target))));
    } catch (err) {
      console.log('Error status:', err);
      setMyErr(err);
    }
  };

  return (
    <Container style={{ height: '40vh' }}>
      <Row className="justify-content-center align-items-center h-100">
        <Col className="col-md-4">
          <div className="text-center mb-3">
            {' '}
            {/* Добавлен элемент div для подписи */}
            <h4>Войти в систему</h4>
          </div>
          <Form onSubmit={login} className="border rounded p-4">
            <FormGroup floating>
              <Input
                id="exampleEmail"
                name="login"
                placeholder="Email"
                type="text"
              />
              <Label for="exampleEmail">
                Логин
              </Label>
            </FormGroup>
            <FormGroup floating>
              <Input
                id="examplePassword"
                name="password"
                placeholder="Пароль..."
                type={showPassword ? 'text' : 'password'}
                autoComplete="off"
              />
              <Label for="examplePassword">
                Пароль
              </Label>
              <div
                className="eye-icon"
                style={{
                  position: 'absolute', right: '20px', top: '50%', transform: 'translateY(-50%)',
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </div>
            </FormGroup>
            <div className="text-center">
              <Button color="success" className="mx-auto">
                Войти
              </Button>
              {myErr === 401 || myErr === 500 ? <div className="text-danger mt-2"> Неккоректно введен Логин или Пароль </div> : null}
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
