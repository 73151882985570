import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Container } from 'reactstrap';
import AuthPage from './components/AuthPage';
import MyAddCategory from './components/MyAddCategory';
import MyAddManagers from './components/MyAddManagers';
import MyMessages from './components/MyMessages';
import MyForm from './components/MyForm';
import MyNavbar from './components/MyNavbar/MyNavbar';
import NoPage from './components/NoPage';
import ProtectedRoute from './HOCs/ProtectedRoute';
import { checkAuth } from './redux/actions/userActions';
import AllMessages from './components/AllMessages';
import Readers from './components/Readers/Readers';
import MyProfile from './components/MyProfile/UserProfile';
import Shablon from './components/Shablon/Shablon';
import MyTasks from './components/MyTasks/MyTasks';
import MyAddGroup from './components/AddGroups/MyAddGroup';

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(checkAuth());
  }, []);

  return (
    <Container>
      <MyNavbar />
      <Routes>
        <Route element={<ProtectedRoute redirect="/" isAllowed={!user.id} />}>
          <Route path="/login" element={<AuthPage />} />
        </Route>
        <Route element={<ProtectedRoute redirect="/login" isAllowed={!!user.id} />}>
          <Route path="/" element={<MyForm />} />
          <Route path="/text" element={<Shablon />} />
          <Route path="/profile" element={<MyProfile />} />
          <Route path="/addgroups" element={<MyAddGroup />} />
          <Route path="/addcategory" element={<MyAddCategory />} />
          <Route path="/addmanagers" element={<MyAddManagers />} />
          <Route path="/readers" element={<Readers />} />
          <Route path="/tasks" element={<MyTasks />} />
          <Route path="/mymessages" element={<MyMessages />} />
          <Route path="/allmessages" element={<AllMessages />} />
        </Route>
        <Route path="*" element={<NoPage />} />
      </Routes>
    </Container>
  );
}

export default App;
