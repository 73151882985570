import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal, ModalBody, ModalHeader, Button,
} from 'reactstrap'; // Import Button from Reactstrap
import { setModal } from '../../redux/Slices/ModalSlice/modalSlice';

export default function MyModal({ already }) {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const toggle = () => dispatch(setModal(!modal));

  return (
    <div>
      <Modal className="mt-5" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Внимание!</ModalHeader>
        {already === 'Логин или пароль уже заняты' ? (
          <ModalBody className="text-center">Пользователь или пароль уже зарезервированы</ModalBody>
        ) : (
          <ModalBody className="text-center">Заполните все поля!!!</ModalBody>
        )}
        <div className="modal-footer">
          <Button color="danger" onClick={toggle}>Закрыть</Button>
        </div>
      </Modal>
    </div>
  );
}
