/* eslint-disable max-len */
import React, { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Table,
} from 'reactstrap';
import { allCategor, deleteCategor } from '../../redux/Slices/CategorySlice/CategorSlice';
import ShablonCategory from './ShablonCategory';
import MyPagination from '../Pagination/MyPagination';
import MyAddCategorModal from './MyAddCategorModal';

function MyAddCategory() {
  const onlyCat = useSelector((state) => state.categor.categories);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [messagePerPage] = useState(8);
  const lastMessageIndex = currentPage * messagePerPage;
  const firstMessageIndex = lastMessageIndex - messagePerPage;
  const currentMessage = onlyCat.slice(firstMessageIndex, lastMessageIndex);
  const [currentAccount, setCurrentAccount] = useState(null);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const nextPage = () => setCurrentPage((prev) => prev + 1);
  const prevPage = () => setCurrentPage((prev) => prev - 1);

  const [shablonModalOpen, setShablonModalOpen] = useState(false);
  const icon = shablonModalOpen ? '➖' : '➕';
  const toggleShablonModal = () => {
    setShablonModalOpen(!shablonModalOpen);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allCategor());
  }, []);
  const handleDelete = (categorId) => {
    dispatch(deleteCategor(categorId));
  };
  const toggleEditModal = (account) => {
    setCurrentAccount(account);
    setEditModalOpen(!editModalOpen);
  };
  return (
    <div className="task-container mt-5" style={{ backgroundImage: "url('background.jpg')", backgroundSize: 'cover' }}>
      <div style={{ marginTop: '15px' }}>
        <Button
          outline
          color="link"
          style={{ float: 'right' }}
          onClick={() => toggleShablonModal()}
        >
          {icon}
        </Button>
        {shablonModalOpen ? <ShablonCategory modalOpen={shablonModalOpen} toggleModal={toggleShablonModal} /> : null}
      </div>
      <Row className="justify-content-center" style={{ marginTop: '20px' }}>
        <Col lg="12">
          <Card className="border-0 shadow-lg">
            <CardBody>
              <CardTitle className="mb-4 mt-3 text-center" tag="h2">
                Таблица Категорий/Подкатегорий
              </CardTitle>
              <Table hover bordered size="">
                <thead style={{ textAlign: 'center' }}>
                  <tr>
                    <th>Айдишники записи</th>
                    <th>Наименование Категории</th>
                    <th>Наименование Подкатегории</th>
                    <th>Редактировать</th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: 'center' }}>
                  {currentMessage.length === 0 ? (
                    <tr>
                      <td colSpan="7" align="center">
                        <b>Нет Задач</b>
                      </td>
                    </tr>
                  ) : currentMessage.map((task, index) => (
                    <tr key={task.id}>
                      {/* <td>{task.id}</td> */}
                      <td>{firstMessageIndex + index + 1}</td>
                      <td>{task.teams_name}</td>
                      <td style={{ textAlign: 'left', paddingLeft: '40px' }}>
                        <pre style={{ margin: '0', whiteSpace: 'pre-wrap' }}>
                          {task.undername}
                        </pre>
                      </td>
                      <td style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                        <Button
                          onClick={() => toggleEditModal(task)}
                          outline
                          color="link"
                          style={{ display: 'inline-block' }}
                        >
                          ✏️
                        </Button>
                        <Button
                          onClick={() => handleDelete(task.id)}
                          outline
                          color="link"
                          style={{ display: 'inline-block' }}
                        >
                          🗑️
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div className="mt-4">
        <div className="mt-3">
          {editModalOpen && (
          <MyAddCategorModal
            isOpen={editModalOpen}
            toggle={() => toggleEditModal(null)}
            account={currentAccount}
          />
          )}
          <MyPagination
            paginate={paginate}
            nextPage={nextPage}
            prevPage={prevPage}
            messagePerPage={messagePerPage}
          />
        </div>
      </div>
    </div>
  );
}

export default memo(MyAddCategory);
