import { SET_LENGTH } from '../types';

export default function lengthReducer(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LENGTH:
      return payload;
    default:
      return state;
  }
}
