import React, { useState } from 'react';
import {
  Alert,
  Button, Card, CardBody, CardTitle, Form, FormGroup, Input, Label,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import MyModal from '../MyModal/MyModal';
import { setModal } from '../../redux/Slices/ModalSlice/modalSlice';
import { allAccounts } from '../../redux/Slices/AccountsSlice/AccountsSlice';

export default function ShablonAddManager({ toggleModal, modalOpen }) {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [login, setLogin] = useState('');
  const [input, setInput] = useState({
    login: '', name: '', surname: '', password: '', level: false,
  });
  const [already, setAlready] = useState();
  const [seeAlert, setSeeAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const changeInput = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const signupUserHandler = (e, inputs) => {
    try {
      e.preventDefault();
      axios.post('/api/addmanagers', inputs)
        .then((res) => {
          if (res.data.message === 'Логин или пароль уже заняты') {
            setAlready(res.data.message);
            dispatch(setModal(!modal));
          } if (res.data.message === 200) {
            dispatch(allAccounts());
            toggleModal(false);
            setName(res.data.name);
            setSurname(res.data.surname);
            setLogin(res.data.login);
            setInput({
              login: '', name: '', surname: '', password: '', level: input.level,
            });
            setSeeAlert(true);
            setTimeout(() => {
              setSeeAlert(false);
            }, 7000);
          } if (res.data.message === 'Заполните все поля!') {
            setAlready(res.data.message);
            dispatch(setModal(!modal));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Card className="border-0 shadow-lg">
      <CardBody>
        <CardTitle className="mb-4 mt-3 text-center" tag="h2">
          Форма для создания пользователей
        </CardTitle>
        <Form>
          <FormGroup>
            <Label for="exampleName">Имя</Label>
            <Input
              id="exampleName"
              name="name"
              placeholder="Введите имя пользователя"
              type="text"
              value={input.name}
              onChange={changeInput}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleSurname">Фамилия</Label>
            <Input
              id="exampleSurname"
              name="surname"
              placeholder="Введите фамилию пользователя"
              type="text"
              value={input.surname}
              onChange={changeInput}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Логин</Label>
            <Input
              id="exampleEmail"
              name="login"
              placeholder="Введите имя пользователя"
              type="text"
              value={input.login}
              onChange={changeInput}
            />
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">Пароль</Label>
            <Input
              id="examplePassword"
              name="password"
              placeholder="Введите пароль пользователя"
              type={showPassword ? 'text' : 'password'}
              value={input.password}
              onChange={changeInput}
            />
            <div className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </div>
          </FormGroup>
          {seeAlert === true
            ? (
              <Alert>
                Пользователь :
                {' '}
                {name}
                {' '}
                {surname}
                {' '}
                {login}
                {' '}
                создан(a)!
              </Alert>
            ) : null}
          <FormGroup check className="mb-4 mt-3">
            <Label check className="container-input">
              <Input
                type="checkbox"
                name="level"
                value={input.level}
                onChange={(e) => setInput((prev) => ({ ...prev, level: e.target.checked }))}
              />
              Администратор
            </Label>
          </FormGroup>
          <MyModal already={already} />
          <Button color="primary" onClick={(e) => signupUserHandler(e, input)}>
            Создать пользователя
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
}
